import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
import ProfileCard from '@/components/ProfileCard/ProfileCard.vue';
import { Getter } from 'vuex-class';
let MemberCard = class MemberCard extends Vue {
    async goToMemberProfile() {
        await history.pushState({ search: this.$store.state.members.searchTerm, page: this.$store.state.members.page }, 'Members', this.$route.fullPath);
        if (this.linkToMember) {
            if (this.profile.id === this.member.id) {
                this.$router.push({ path: `/profile` });
            }
            else {
                await this.$store.dispatch('members/setActiveMember', this.member);
                this.$router.push({ path: `/member/${this.member.id}` });
            }
        }
    }
    async goToConversation() {
        if (this.linkToMember) {
            await this.$store.dispatch('conversations/setActiveConversationMember', this.member);
            this.$router.push({ path: `/chat/${this.member.id}` });
        }
    }
};
__decorate([
    Prop()
], MemberCard.prototype, "member", void 0);
__decorate([
    Prop({ default: true })
], MemberCard.prototype, "linkToMember", void 0);
__decorate([
    Prop({ default: true })
], MemberCard.prototype, "showMemberChat", void 0);
__decorate([
    Getter('getProfile', { namespace: 'user' })
], MemberCard.prototype, "profile", void 0);
MemberCard = __decorate([
    Component({
        components: {
            ProfileCard
        }
    })
], MemberCard);
export default MemberCard;
