import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let OverlayBasic = class OverlayBasic extends Vue {
    onClose() {
        this.$emit('close', true);
    }
};
OverlayBasic = __decorate([
    Component
], OverlayBasic);
export default OverlayBasic;
