import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SearchInput from '../../../components/FormInputs/SearchInput/SearchInput.vue';
import MemberCard from './MemberCard/MemberCard.vue';
import { Getter } from 'vuex-class';
import Pagination from '@/components/Pagination/Pagination.vue';
let MemberLookup = class MemberLookup extends Vue {
    constructor() {
        super(...arguments);
        this.searchValue = '';
        this.searchError = [];
        this.loading = true;
        this.paginationLoaded = false;
        this.searchTimeout = null;
    }
    async searchUpdated(newVal) {
        // We do this timeout clear timeout dance
        // so that the search is only triggered 1s after the user has stopped typing.
        // We trigger loading immediately though to provide feedback
        clearTimeout(this.searchTimeout);
        this.loading = true;
        // Set the search term & rest page
        await this.$store.dispatch('members/setSearch', newVal);
        await this.$store.dispatch('members/setPage', 1);
        let self = this;
        this.searchTimeout = setTimeout(async function () {
            // Call the page load
            await self.$store.dispatch('members/getMembers');
            self.loading = false;
        }, 1000);
    }
    async mounted() {
        await this.resetMembersValues();
        await this.$store.dispatch('members/getMembers');
        this.paginationLoaded = true;
        this.loading = false;
    }
    async beforeDestroy() {
        await this.resetMembersValues();
    }
    async resetMembersValues() {
        await this.$store.dispatch('members/setSearch', '');
        await this.$store.dispatch('members/setFilter', '');
        await this.$store.dispatch('members/setPage', 1);
    }
    async paginate(page) {
        this.loading = true;
        await this.$store.dispatch('members/setPage', page);
        await this.$store.dispatch('members/getMembers');
        this.loading = false;
    }
    async navigateToMemberChat(member) {
        this.$router.push({ path: `/chat/${member.id}?content=${this.shareContent}` });
    }
};
__decorate([
    Prop()
], MemberLookup.prototype, "shareContent", void 0);
__decorate([
    Prop()
], MemberLookup.prototype, "shareIntro", void 0);
__decorate([
    Getter('getMembers', { namespace: 'members' })
], MemberLookup.prototype, "members", void 0);
__decorate([
    Getter('pagination', { namespace: 'pagination' })
], MemberLookup.prototype, "pagination", void 0);
__decorate([
    Watch('searchValue')
], MemberLookup.prototype, "searchUpdated", null);
MemberLookup = __decorate([
    Component({
        components: {
            SearchInput,
            MemberCard,
            Pagination
        }
    })
], MemberLookup);
export default MemberLookup;
